import {ref, computed, getCurrentInstance, reactive, toRefs, onMounted, defineComponent, provide,nextTick} from 'vue';
import GenCodeUtils from "./genCodeUtils.js";
import { Math } from "core-js";
const GenCodeCardHelper = defineComponent({
    name: 'GenCodeCard',
    title: "代码生成模块",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            refMap:new Map(),
            mainTbFields: [],//主表的字段
            mainTbTreeData: [],//主表下拉树
            dialogVisible: false,//穿梭狂弹出框组件的显示/影藏
            chooseTypeVisible:false,//代码生成种类选择弹出框的显示/影藏
            titles: ["未选字段", "已选字段"],//穿梭框左右的标题
            transformLeftData: [],//穿梭框左边的数据（每项数据：{key:'',value''}）
            transformRightData: [],//穿梭框右边的数据：已选项。（每项数据：只包含key）
            billTypeTreeData:[],//单据类型数据源
            curOperGrid: "",//当前正在操作的明细表格，供穿梭框点击确认的时候用，方便把已选择的穿梭框数据回填到正在操作的表格
            cardDetailGridArr: [],//卡片明细表格数组,卡片有多少个明细表格
            compParams: {//代码生成模块组件传给dialog的初始化参数
                modelPath: "/genCode",
                hsDetails: true//有明细表格
            },
            //卡片显示字段明细表格中是否必填列是一个常量下拉框，该下拉框取值来自于此
            yesNoSel: [{ value: 0, label: "否" },{ value: 1, label: "是" }],
            //字段的类型
            cardFieldType: [
                { value: "text", label: "text" },
                { value: "input", label: "input" },
                { value: "cascader", label: "cascader" },
                { value: "comboTree", label: "comboTree" },
                { value: "comboGrid", label: "comboGrid" },
                { value: "switch", label: "switch" },
                { value: "select", label: "select" },
                { value: "datePicker", label: "date-picker" },
                { value: "dateRangePicker", label: "date-range-picker" },
                { value: "inputNumber", label: "el-input-number" },
                { value: "rate", label: "el-rate" },
                { value: "slider", label: "el-slider" },
                { value: "wangEditor", label: "wangEditor" }
            ],
            //明细可编辑表格可用字段类型
            fieldType: [
                { value: "text", label: "text" },
                { value: "input", label: "input" },
                { value: "cascader", label: "cascader" },
                { value: "comboTree", label: "comboTree" },
                { value: "comboGrid", label: "comboGrid" },
                { value: "switch", label: "switch" },
                { value: "select", label: "select" },
                { value: "datePicker", label: "date-picker" },
                { value: "dateRangePicker", label: "date-range-picker" },
                { value: "inputNumber", label: "el-input-number" },
                { value: "rate", label: "el-rate" },
                { value: "slider", label: "el-slider" }
            ],
            //表格字段的对其方式
            alignType: [
                { value: "left", label: "left" },
                { value: "center", label: "center" },
                { value: "right", label: "right" }
            ],
            //关联子表表格参数
            //它和后面几个表格参数不一样，没有自定义工具条，采用的是默认的新增按钮作为工具条，所以单独拎出来
            childTableDetailParam: {
                detailParam: {
                    canPage: false,
                    queryParam: {
                        detailType: "childTable"
                    },
                    modelMethod: "/genCode/detail?t="+Math.random()*1000
                }
            },
            queryFieldsDetailParam: {},//列表查询条件表格参数
            listFieldsDetailParam: {},//列表显示字段的表格参数
            cardFieldsDetailParam: {},//卡片显示字段的表格参数
            //代码生成模块表单属性
            form: GenCodeUtils.form,
            //选择要生成文件种类form表单
            genTypeForm:{
                controller:true,helper:true,bean:true,detailBean:true,buffer:true,
                dao:true,detailDao:true,card:true,cardHelper:true,list:true,
                showDetail:true,report:false,bmap:false
            },
            //表单验证规则
            rules: GenCodeUtils.rules
        })
        onMounted(()=>{

        })


        //---------------------------computed------------
        //字段下拉框（根据表名显示字段）
        const fieldComboParam=computed(()=>{
            return (tbName) => {
                return {
                    comboParam: {
                        comboId:"fieldCombo",
                        txtField: "F_CODE",
                        modelMethod: "/genCode/combo",
                        comboType: "fieldCombo",
                        tbName: tbName
                    }
                }
            };
        })
        //根据子表动态显示的tab表格，用计算属性来定义他们的detailParam，根据选取的子表表名来区分
        const cardDetailFieldsDetailParam=computed(()=>{
            return (tbName) => {
                return {
                    detailParam: GenCodeUtils.cardDetailFieldsDetailParam(tbName,dataObj)
                }
            }
        })

        const init=()=>{
            //初始化列表查询字段、列表显示字段、卡片显示字段表格的参数
            //这里就不能写在mounted里面了，因为子组件EditTable的mounted需要用到detailType，
            //会先调用子组件的mounted再调用父组件的mounted，这样的话子组件就会报找不到detailType的错误。
            //写在created里面的话，会先调用created再调用mounted，这样子组件就不会找不到detailType了。
            GenCodeUtils.build234GridToolBarDetailParam(dataObj.queryFieldsDetailParam, "grid234AddHandler", "queryFields");
            GenCodeUtils.build234GridToolBarDetailParam(dataObj.listFieldsDetailParam, "grid234AddHandler", "listFields");
            GenCodeUtils.build234GridToolBarDetailParam(dataObj.cardFieldsDetailParam, "grid234AddHandler", "cardFields");
        }
        init();

        //打开弹出框的回调事件
        const beforeOpen=async(data,addOrLoad)=>{
            if(data.billTypeData)dataObj.billTypeTreeData =JSON.parse(data.billTypeData);
            //为各个明细表格设置主表id，卡片的明细表格的主表id在创建的时候就已经指定了，而创建卡片明细表格是根据一个数组来创建的，这个数组在下面赋值，
            //赋值的时候会构建他们的参数，那个时候form的id有值了，这里就不需要指定了
            //前面四个表格在构建出来的时候，弹出框还未打开，因此还未请求后台，于是form就没有值，id就为空
            //因此才在beforeOpend里面为各个表格把id给添加上，因为这个时候form已经从请求后台结果中赋值了，然后在手动调用表格的查询方法就行查询数据
            //初始化主表下拉树的值
            const res = await utils.$$api.tableTreeData();
            dataObj.mainTbTreeData = res.data;
            await loadDetails({data:{id:dataObj.form.id}},addOrLoad)
        }
        const loadDetails=async(res,addOrLoad)=>{
            dataObj.childTableDetailParam.detailParam.queryParam.id = res.data.id;
            dataObj.queryFieldsDetailParam.detailParam.queryParam.id = res.data.id;
            dataObj.listFieldsDetailParam.detailParam.queryParam.id = res.data.id;
            dataObj.cardFieldsDetailParam.detailParam.queryParam.id = res.data.id;

            //EditTable关闭了自动发送请求加载数据，所以在弹出框打开动画结束的时候开始查询各个明细表格，可以写在一个方法里面把所有表格的数据请求回来，然后赋值给每个表格
            //我没有这样做，因为这个模块就开发人员自己用，没有管效率
            await dataObj.refMap.get('childTable').reloadGrid();
            dataObj.cardDetailGridArr = getChildTableAndInfos();//构造卡片明细表格的数组，动态创建出有多少个卡片明细表格
            await dataObj.refMap.get('queryFields').reloadGrid();
            await dataObj.refMap.get('listFields').reloadGrid();
            await dataObj.refMap.get('cardFields').reloadGrid();
            //由于明细表格都采用EditTable组件，而EditTable关闭了自动发送请求加载数据，因此这里手动查询表格数据，注意：不能用forEach和await结合用
            for (let i = 0; i < dataObj.cardDetailGridArr.length; i++) {
                let grid = dataObj.cardDetailGridArr[i]["tbName"];
                await dataObj.refMap.get(grid).reloadGrid();
            }
            if("/load"==addOrLoad){//如果是编辑，需要把已经选择好的主表字段给存下来,在弹出穿梭框的时候要用到，免得去后台查询
                let fields = dataObj.refMap.get('table').getCheckedNodes()[0].data.fields;
                dataObj.mainTbFields.length = 0;//清空主表字段,否则点击重置或者重新加载卡片的时候，会造成字段不断增加
                for (let fieldName in fields) {
                    dataObj.mainTbFields.push({ fieldName: fieldName, fieldCaption: fields[fieldName] });
                }
            }
        }
        //主表变了，清空所有的明细
        const clearDetail=()=>{
            GenCodeUtils.getInstByName("childTable",dataObj).clearDetailData();
            GenCodeUtils.getInstByName("queryFields",dataObj).clearDetailData();
            GenCodeUtils.getInstByName("listFields",dataObj).clearDetailData();
            GenCodeUtils.getInstByName("cardFields",dataObj).clearDetailData();
            //这里可以考虑从refMap中删除cardDetailGridArr中的项
            dataObj.cardDetailGridArr.length = 0;//卡片明细数组清空
            dataObj.transformRightData.length = 0;//穿梭框已选清空(万一穿梭框右边部分有值，再选一张主表，弹出穿梭框的时候，数据有可能会乱)
        }

        //主表变化事件
        const change=(val)=>{
            clearDetail();
            let checkedNodes=dataObj.refMap.get('table').getCheckedNodes();
            if(checkedNodes.length>0){
                //根据选择的主表，把模块名称、模块描述给填上
                let caption = dataObj.refMap.get('table').getCheckedNodes()[0].data.caption;
                dataObj.form.modelCaption = caption;
                if(dataObj.form.billFlag==0)
                dataObj.form.modelName = GenCodeUtils.getJavaNameByDbName(val);
                //后台返回给前台的主表数据中包含了该主表的所有字段信息，但是字段信息是以对象的形式返回的，
                //这里我们解析为一个数组形式，放到data中，在弹出穿梭框的时候要用到，免得去后台查询
                dataObj.mainTbFields.length = 0;//清空主表字段
                let fields = dataObj.refMap.get('table').getCheckedNodes()[0].data.fields;
                for (let fieldName in fields) {
                    dataObj.mainTbFields.push({ fieldName: fieldName, fieldCaption: fields[fieldName] });
                }
            }
        }
        //是否单据下拉select发生改变事件
        const billFlagChange=(val)=>{//如果不是单据，则清空单据模块cascader
            if(val==0)dataObj.form.billModel='';
        }
        //单据类型改变事件
        const billTypeChange=(val)=>{
            dataObj.form.modelName =val;
        }
        //关联子表变化事件
        //1、选择好子表之后，更新tab的个数，最后几个tab是显示明细列表的内容。
        //2、关联的子表下拉选择改变时，动态设置该行字段下拉grid的查询条件，以便重新加载该子表的字段出来
        //3、检查是否存在子表重复的情况，因为不同模块是否定义了相同的table，这个应该不存在
        //4、为该行添加新的属性并且赋值
        const tbDetailChange=(row)=>{
            //不管本次子表是否为空，都重新去遍历子表明细表格，把所有的子表以及该子表的字段放进一个数组中。方便动态构造卡片的明细表格
            dataObj.cardDetailGridArr=getChildTableAndInfos();
            if (null == row.F_TABLE) return;
            //重新查询字段下拉框
            let tbName = row.F_TABLE;
            dataObj.refMap.get('fieldCombo').setQueryParams({ tbName: tbName });
            let childTableGridData = dataObj.refMap.get('childTable').getDetailData();
            if (childTableGridData.length != 0 && !checkDoubleChildTableGrids()) {
                utils.$$tools.info({ message: "子表存在重复" });
            }
            row.F_MODEL_NAME=GenCodeUtils.getJavaNameByDbName(tbName);//设置明细表格的模块名称，在生成代码的时候，会以该模块名称打头
            dataObj.refMap.get('childTable').setRowData(row, row.rowIndex);
        }
        //获取所有的子表以及该子表的字段信息等等
        const getChildTableAndInfos=()=>{
            let childTableGridData = dataObj.refMap.get('childTable').getDetailData();
            let tableArr = [], tabDataArr = [];
            childTableGridData.forEach(item=>{
                if (utils.$$str.isNotEmpty(item.F_TABLE) && tableArr.indexOf(item.F_TABLE) == -1) {
                    let tbInfo = getTbInfoByTbName(dataObj.mainTbTreeData, item.F_TABLE);//表数组中找到指定的表
                    tabDataArr.push({ tbName: tbInfo.label, tbCaption: tbInfo.caption, tbFields: tbInfo.fields });
                    tableArr.push(tbInfo.label);
                }
            });
            return tabDataArr;
        }

        //根据表名查询该表的详细信息，不用去后台，因为主表那里有所有的信息
        const getTbInfoByTbName=(tbDataInst, tbName)=>{
            for (let i = 0; i < tbDataInst.length; i++) {
                if (tbDataInst[i].children) {//如果是分类，需要递归调用一下，直到找到需要的表
                    let tbInfo = getTbInfoByTbName(tbDataInst[i].children, tbName);
                    if(tbInfo) return tbInfo;
                } else if (tbName == tbDataInst[i].label) {
                    return tbDataInst[i];
                }
            }
        }
        //检查关联子表表格是否存在重复的子表名称
        const checkDoubleChildTableGrids=()=>{
            let childTableGridData = dataObj.refMap.get('childTable').getDetailData();
            let tableArr = [];
            childTableGridData.forEach(item=>{
                if (tableArr.indexOf(item.F_TABLE) == -1) tableArr.push(item.F_TABLE);
            });
            return tableArr.length == childTableGridData.length;
        }

        //卡片显示字段明细表格结束编辑某一行的时候，给字段是否必填设置了一个显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{
            if ("cardFields" == detailType){
               nextTick(()=>{
                   console.log(dataObj.refMap.get('cardFieldRequired'))
                   row.F_REQUIRED_NAME = dataObj.refMap.get('cardFieldRequired').selected.currentLabel;//卡片显示字段表格中是否必填列显示名称
                   dataObj.refMap.get('cardFields').setRowData(row, index);
               })
            }
            return true;
        }
        //关联子表表格头部的新增按钮事件中的新增之前方法回调，如果存在重复的子表名称将不会允许继续新增
        const beforeAddHandler=()=>{
            if (utils.$$str.isEmpty(dataObj.form.tbName)) {
                utils.$$tools.info({ message: "请先选择主表" });
                return false;
            } else {
                let childTableGridData = dataObj.refMap.get('childTable').getDetailData();
                //检查是否存在子表重复添加的情况
                if (childTableGridData.length == 0 || checkDoubleChildTableGrids()) {
                    return true;
                } else {
                    utils.$$tools.info({ message: "子表存在重复" });
                }
            }
        }
        //各个明细表格中固定列的删除后事件
        const afterDelHandler=(detailType, row, index)=>{
            if ("childTable" == detailType) {//如果是删除的关联子表表格某行
                dataObj.cardDetailGridArr = getChildTableAndInfos();//重新计算需要显示的表格明细有哪些
            }
        }

        //-----------------------明细表格头部新增按钮事件------------------------------
        //列表查询字段、列表显示字段、卡片显示字段表格头部的新增按钮，会弹出穿梭框，让选择要显示哪里主表字段
        const grid234AddHandler=async (detailType)=>{
            dataObj.curOperGrid = detailType;//当前操作的是代码生成模块的哪个明细表格
            await genTransformContent(dataObj.mainTbFields,detailType);
        }

        //由子表生成的卡片明细表格头部的新增按钮事件，添加卡片明细表格要显示的字段
        const cardDetailAddHandler=async (tbName)=>{
            dataObj.curOperGrid = tbName;//当前操作的是代码生成模块的哪个明细表格
            await genTransformContent(getDetailGridFieldArr(tbName),tbName);
        }
        //-----------------------卡片明细表格头部新增按钮事件------------------------------
        //根据卡片明细表格用到的表名称，找出该明细表格可以操作的字段并组装为数组
        const getDetailGridFieldArr=(tbName)=>{
            let item = dataObj.cardDetailGridArr.find(item=>{
                if ((item.tbName) == tbName) return item;
            });

            let tbFieldArr = [];
            let tbFields = item.tbFields;
            for (let fieldName in tbFields) {
                tbFieldArr.push({ fieldName: fieldName, fieldCaption: tbFields[fieldName] });
            }
            return tbFieldArr;
        }

        //生成穿梭框左右内容，先把左右两边的数据清空，然后往左右两边添加数据，左边添加的是全部数据，但是左边会自动去掉右边已存在的项
        const genTransformContent=async(transformLeftData,detailType)=>{
            if (utils.$$str.isEmpty(dataObj.form.tbName)) {
                utils.$$tools.info({ message: "请先选择主表" });
                return false;
            } else {
                //得到当前操作表格的实例对象，然后用该实例对象得到该表格已经存在的内容，也就是字段
                let inst=GenCodeUtils.getInstByName(detailType,dataObj);
                let detailGridExistFields=inst.getDetailData();
                //先把已选字段数组清空，然后把已经存选择的字段添加进已选数组。这样就不会出现重复添加的情况了。
                dataObj.transformRightData.length = 0;
                detailGridExistFields.forEach(item=>{
                    dataObj.transformRightData.push(item.F_FIELD);//穿梭框右边的数组只需要提供key就可以
                });
                //把穿梭框左边的数据先清空，然后在把要添加进左边的数据一个个放进左边的穿梭框，和上面一样，这样可以避免穿梭框出现显示重复的数据
                dataObj.transformLeftData.length = 0;
                transformLeftData.forEach(item=>{
                    let row = { label: item.fieldCaption, key: item.fieldName,disabled:false };
                    dataObj.transformLeftData.push(row);//穿梭框左边的数组必须包含key和value，也就是每项的全部信息
                });
                dataObj.dialogVisible = true;//显示穿梭框的弹出框
                return true;
            }
        }
        //得到穿梭框中某项的具体信息
        const getTransformInfoByValue=(transformTotalData, val)=>{
            return transformTotalData.find(item=>{
                if (val == item.fieldName) return item;
            });
        }

        //单击穿梭框的确认按钮，把选择的信息回填到相应的明细表格中,先清空已有的，再把选择的添加上去
        const chooseFieldsSureHandler=async()=>{
            let gridInst=GenCodeUtils.getInstByName(dataObj.curOperGrid,dataObj);//得到当前正在操作的表格实例对象
            await gridInst.clearDetailData();//清空当前表格的行
            switch (dataObj.curOperGrid) {
                case "queryFields"://处理列表查询字段信息
                    dataObj.transformRightData.forEach(item=>{//循环穿梭框右边的所有项
                        let result = getTransformInfoByValue(dataObj.mainTbFields, item);//根据右边的key得到穿梭框中某项的具体信息
                        let row = { F_FIELD: item, F_FIELD_CAPTION: result.fieldCaption,F_FIELD_TYPE: 'input', EDIT: false };
                        gridInst.addRow(row);
                    });
                    break;

                case "listFields"://处理列表要显示的字段信息
                    dataObj.transformRightData.forEach(item=>{
                        let result = getTransformInfoByValue(dataObj.mainTbFields, item);
                        let row = {F_FIELD: item, F_FIELD_CAPTION: result.fieldCaption,F_TITLE_ALIGN: "center", F_VALUE_ALIGN: "center", EDIT: false};
                        gridInst.addRow(row);
                    });
                    break;
                case "cardFields"://处理卡片要显示的信息
                    dataObj.transformRightData.forEach(item=>{
                        let result = getTransformInfoByValue(dataObj.mainTbFields, item);
                        let row = {F_FIELD: item, F_FIELD_CAPTION: result.fieldCaption,F_REQUIRED:"0",F_REQUIRED_NAME:"否",F_FIELD_TYPE: 'input', EDIT: false};
                        gridInst.addRow(row);
                    });
                    break;
                default://肯定是卡片明细
                    let tbFieldArr = getDetailGridFieldArr(dataObj.curOperGrid);
                    dataObj.transformRightData.forEach(item=>{
                        let result = getTransformInfoByValue(tbFieldArr, item);
                        let row = {F_FIELD: item, F_FIELD_CAPTION: result.fieldCaption,F_FIELD_TYPE:'input',F_TITLE_ALIGN: "center", F_VALUE_ALIGN: "center", EDIT: false};
                        gridInst.addRow(row);
                    });
                    break;
            }
            dataObj.dialogVisible = false;
        }
        //把所有tab明细表格的内容全部放入insert里面，虽然穿梭框的确认事件已经放过了，
        //但是你没有点击穿梭框咋办？所以保险的做法是保存的时候再去遍历一遍明细，让明细行全部insert，别担心重复
        //在getSaveDetailData的时候，用Set集合对insert会去重的。
        const setSaveData=(gridName)=>{
            let gridInst=GenCodeUtils.getInstByName(gridName,dataObj);//得到表格的实例对象
            return new Promise((resolve, reject) => {
                let tbData = gridInst.getDetailData();//根据表格实例对象得到表格的所有明细数据
                tbData.forEach(item=>{
                    //把表格所有明细数据全部设置为插入状态，后台处理的时候，是先删掉就数据，在插入新的数据，所以前台这样处理，否则后台要考虑删除和更新的情况
                    gridInst.setInsert(item);
                });
                resolve();
            })
        }

        const setDetailsJson=async()=>{
            //构建所有卡片明细表格的保存数据并赋值给form的一个变量，方便后台接收.每个卡片明细表格数据的key，我们取子表明细的表名称
            let cardDetailTitleArr = [];//所有卡片明细表格要保存的数据，全部放在这个数组里面
            for (let i = 0; i < dataObj.cardDetailGridArr.length; i++) {
                let gridName=dataObj.cardDetailGridArr[i]["tbName"];
                await setSaveData(gridName);
                dataObj.form[gridName] =await dataObj.refMap.get(gridName).getSaveDetailData();
                cardDetailTitleArr.push(gridName);
            }
            //设置明细表格的保存数据
            await setSaveData("childTable");
            await setSaveData("queryFields");
            await setSaveData("listFields");
            await setSaveData("cardFields");
            //得到明细表格的保存数据并赋值给form的一个变量，方便后台接收
            dataObj.form["childTable"] =await dataObj.refMap.get('childTable').getSaveDetailData();//关联子表明细数据构造
            dataObj.form["queryFields"] =await dataObj.refMap.get('queryFields').getSaveDetailData();//列表查询字段明细数据构造
            dataObj.form["listFields"] =await dataObj.refMap.get('listFields').getSaveDetailData();//列表显示字段明细数据构造
            dataObj.form["cardFields"] =await dataObj.refMap.get('cardFields').getSaveDetailData();//卡片显示字段数据构造
            dataObj.form["cardDetailTitleArr"] = cardDetailTitleArr;//给后台传递一个固定的名字，用来接收卡片明细的情况
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //如果是单据，必须旋转单据模块
            if(dataObj.form.billFlag==1 && utils.$$str.isEmpty(dataObj.form.billModel)){
                utils.$$tools.success({message: "请选择单据模块!"});
                return false;
            }
            return true;//如果只想根据主表生成bean、dao等，就不需要任何明细表格的数据了，那么就不需要上面的验证
        }
        //点击生成代码按钮
        const genCodeHandler=()=>{
            let tbChild=dataObj.refMap.get('childTable').getDetailData();
            if(tbChild.length==0){//说明卡片不需要显示明细
                dataObj.genTypeForm.detailBean=false;
                dataObj.genTypeForm.detailDao=false;
                dataObj.genTypeForm.showDetail=false;
            }
            dataObj.chooseTypeVisible=true;
        }
        //生成代码选择框确认事件
        const chooseTypeSureHandler=async()=>{
            let params=Object.assign({id: dataObj.form.id},dataObj.genTypeForm); //把要生成代码的种类和使用那条代码生成规则传入后台
            const res = await utils.$$api.genCode(params);
            utils.$$tools.success({ message: res.msg });
            dataObj.chooseTypeVisible=false;
        }
        return{
            ...toRefs(dataObj),fieldComboParam,cardDetailFieldsDetailParam,beforeOpen,loadDetails,clearDetail,
            change,billFlagChange,billTypeChange,tbDetailChange,getTbInfoByTbName,checkDoubleChildTableGrids,
            beforeEndEdit,beforeAddHandler,afterDelHandler,grid234AddHandler,cardDetailAddHandler,getDetailGridFieldArr,
            genTransformContent,getTransformInfoByValue,chooseFieldsSureHandler,setSaveData,setDetailsJson,
            beforeSaveHandler,genCodeHandler,chooseTypeSureHandler
        }
    }
});
export default GenCodeCardHelper;